@import '../../assets/styles/mixins';

.backdrop {
  min-width: calc(100vw / var(--zoom));
  min-height: calc(100vh / var(--zoom));
}

div:has(.dialog) {
  z-index: 99999;
}

.dialog {
  max-width: 1100px;

  @include onMobile {
    z-index: 99999 !important;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.ViewImageSectionModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-radius: 25px;
  padding: 60px 60px 40px;

  @include onMobile {
    justify-content: unset;
    height: 100dvh;
    padding-top: 81px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0;
  }

  .closeButton {
    position: absolute;
    top: 22px;
    right: 20px;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .imageContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;

    .switchButton {
      position: absolute;
      top: 50%;
      left: -40px;
      transform: translateY(-50%);
      padding: 0;
      background-color: transparent;
      border: none;

      @include onMobile {
        left: -20px;
      }

      &:last-child {
        top: calc(50% - 10px);
        left: auto;
        right: -40px;
        transform: translateY(-50%) rotate(180deg);

        @include onMobile {
          right: -20px;
        }
      }
    }

    .image {
      width: 100%;
      height: 474px;
      object-fit: contain;

      @include onMobile {
        height: 255px;
      }
    }
  }

  .text {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    @include onMobile {
      margin-top: 0;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }
}
