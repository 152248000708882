.Textarea {
  width: 100%;
  height: 145px;
  padding: 20px;
  padding-left: 25px;
  padding-right: 75px;
  font-size: 24px;
  line-height: normal;
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 16px 34px -18px rgba(0, 76, 189, 0.08);
  border: 1px solid #666666;
  border-radius: 18px;
  resize: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    line-height: 86px;
    text-align: center;
    background-color: #ffffff;
    color: var(--black);
    font-size: 22px;
    line-height: 33px;
    font-weight: bold;
    cursor: auto;
  }

  &::placeholder {
    color: var(--black);
    opacity: 0.4;
  }
}
