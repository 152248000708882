@import '../../../assets/styles/_variables.scss';
@import '../../../assets/styles/_mixins.scss';

.Sidebar {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 373px;
  min-width: 373px;
  padding-top: 33px;
  padding-bottom: 30px;
  border-right: 1px solid #dddddd;
  transition: all 0.3s;

  @include onMobile {
    display: none;
  }

  &.collapsed {
    width: 126px;
    min-width: 126px;
    padding-top: 66px;
    overflow: hidden;

    .logoContainer {
      display: flex;
      justify-content: center;
      padding-left: 0;
      margin-bottom: 59px;

      img {
        width: 100px;
      }
    }

    .title {
      opacity: 0;
    }
  }

  .collapseButtonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    padding-right: 51px;
    .collapseButton {
      width: 36px;
      padding: 0;
      background-color: transparent;
      border: none;

      img {
        width: 100%;
      }
    }
  }

  .title {
    transition: all 0.3s;
  }

  .logoContainer {
    height: 53px;
    margin-bottom: 92px;
    padding-left: 31px;
    transition: all 0.3s;

    .logo {
      width: 254px;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 10px;
    margin: 0;
    padding: 0;

    a {
      display: flex;
      align-items: center;
      font-family: Poppins;
      font-size: 26px;
      font-weight: 600;
      line-height: 39px;
      text-decoration: none;
      color: $textGray;
      white-space: nowrap;

      &.active {
        color: $red;
        background-color: #f8f8f8;

        .iconContainer {
          background: linear-gradient(
            160.04deg,
            #fdb258 -65.33%,
            #f5658c 83.85%,
            #f8585c 233.04%
          );
          border: none;
        }
      }

      &:hover {
        background-color: #f8f8f8;
      }

      li {
        display: flex;
        align-items: center;
        gap: 20px;
        height: 86px;
        padding-left: 29px;
      }

      .iconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 52px;
        min-width: 52px;
        max-width: 52px;
        height: 52px;
        border: 2px solid $textGray;
        border-radius: 50%;
      }
    }
  }
}
