@import '../../assets/styles/mixins';

.backdrop {
  min-width: calc(100vw / var(--zoom));
  min-height: calc(100vh / var(--zoom));
}

.dialog {
  max-width: 1524px;
}

.ViewerNotesModal {
  position: relative;
  border-radius: 10px;
  padding-top: 48px;
  padding-left: 76px;
  padding-right: 75px;
  padding-bottom: 49px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 46px;

    .closeButton {
      padding: 0;
      background-color: transparent;
      border: none;
    }

    .title {
      font-weight: 600;
      font-size: 48px;
      line-height: 100%;
      letter-spacing: 0%;
      color: var(--black);
    }
  }

  .attachments {
    display: block;
    flex-wrap: wrap;
    width: 100%;
    height: calc(554px / var(--zoom));
    margin-bottom: 40px;
    padding-bottom: 28px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid var(--text);
    border-radius: 18px;
    overflow-y: auto;

    &.withText {
      height: calc(449px / var(--zoom));
    }

    .uploader {
      display: flex;
      flex-direction: column;
      gap: 21px;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      cursor: pointer;

      p {
        margin: 0;
        font-weight: 400;
        font-size: 22px;
        line-height: 100%;
        letter-spacing: 0%;
        color: var(--black);
        opacity: 0.4;
      }
    }
  }

  .inputContainer {
    margin-bottom: 40px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info {
      margin: 0;
      font-weight: 400;
      font-size: 24px;
      line-height: 100%;
      letter-spacing: 0%;
      color: var(--black);
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 40px;

      .importButton {
        width: calc(192px / var(--zoom));
        height: calc(66px / var(--zoom));
        font-weight: 600;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: 0%;
        color: white;
        background-color: var(--black);
        border-radius: 7px;
      }
    }
  }
}
