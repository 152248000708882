@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.dialog {
  max-width: 746px;
  max-height: 652px;

  @include onMobile {
    max-width: 100%;
    max-height: 100%;
  }
}

.backdrop {
  min-width: calc(100vw / var(--zoom));
  min-height: calc(100vh / var(--zoom));
}

.ConfirmActionModal {
  padding: 40px 106px 48px 48px;
  position: relative;
  border-radius: 15px;

  @include onMobile {
    padding: 32px 24px;
  }

  .closeIcon {
    position: absolute;
    top: 36px;
    right: 36px;
    cursor: pointer;

    @include onMobile {
      top: 24px;
      right: 24px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin-bottom: 24px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 133%;
      color: $text;

      @include onMobile {
        font-size: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      font-size: 24px;
      line-height: 100%;
      color: $text;

      @include onMobile {
        margin-bottom: 24px;
        font-size: 20px;
      }
    }

    .buttons {
      display: flex;
      column-gap: 48px;
      align-self: flex-end;

      @include onMobile {
        gap: 24px;
      }
    }
  }
}
