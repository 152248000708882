.sectionContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98px;
  height: 68px;

  video,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.title {
  margin-top: 7px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  &.current {
    color: var(--primary);
  }
}
