@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.Library {
  min-height: calc(100vh / var(--zoom) - var(--header-height));

  img {
    vertical-align: unset;
  }

  .container {
    padding: 16px 72px;
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 25px;

    @include onMobile {
      padding-top: 0;
      padding-left: 20px;
      padding-right: 20px;
    }

    .libraryHeader {
      margin-bottom: 52px;
      display: flex;
      align-items: center;
      position: relative;
      line-break: anywhere;

      @include onMobile {
        height: unset;
        margin-bottom: 13px;

        &.trash {
          padding-top: 20px;
        }
      }

      .warning {
        color: var(--text);

        @include onMobile {
          line-break: initial;
        }
      }

      h2 {
        margin-bottom: 0;

        @include onMobile {
          display: none;
        }
      }

      .buttonsContainer {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 20px;

        @include onMobile {
          display: none;
        }
      }
    }

    .subfolders {
      margin-bottom: 80px;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;

      @include onMobile {
        margin-bottom: 0;
        flex-direction: column;
        gap: 0;
      }

      .subfolderContainer {
        display: flex;
        flex-basis: calc(33% - 12px);
        align-items: center;

        @include onMobile {
          margin-bottom: 14px;
          padding-top: 18px;
        }
      }
    }

    .tableContainer {
      display: flex;
      justify-content: center;

      .table {
        width: 100%;
        table-layout: auto;

        tr {
          position: relative;
        }

        thead tr,
        tbody tr:not(:last-of-type) {
          &::after {
            content: '';
            height: 1px;
            display: block;
            position: absolute;
            left: 8px;
            right: 8px;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.05);

            @include onMobile {
              display: none;
            }
          }
        }

        thead {
          height: 50px;

          @include onMobile {
            display: none;
          }

          tr {
            th {
              padding: 0 6px 12px;
              padding-bottom: 28px;
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 36px;
              color: var(--black);
              cursor: pointer;

              &:first-of-type {
                padding-left: 0;
              }

              .sortOrder {
                .chevron {
                  width: 15px;
                  margin-left: 7px;
                }

                &.asc {
                  .chevron {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
        }

        tbody {
          tr {
            position: relative;
            height: 100%;

            td {
              position: relative;
              min-height: 120px;
              padding: 8px 6px 8px;
              font-weight: 500;
              font-size: 24px;
              line-height: 36px;
              color: var(--text);

              @include onMobile {
                height: unset;
                min-height: 70px;
                padding: 0;
                padding-top: 25px;
                padding-bottom: 25px;
                border-bottom: 1px solid #d9d9d9;
              }

              .count {
                @include onMobile {
                  font-size: 18px;
                  line-height: 27px;
                  color: var(--text);
                }
              }

              .viewedContainer {
                display: flex;
                align-items: center;

                @include onMobile {
                  gap: 19px;
                }
              }

              .viewedMark {
                position: absolute;
                left: 125px;

                @include onMobile {
                  position: static;
                  margin-top: 2px;
                }
              }

              .viewButtonContainer {
                position: relative;
                .viewedButtonIcon {
                  position: absolute;
                  bottom: 0;
                  right: 0;
                }
              }

              .imagesContainer {
                position: relative;
                .newIcon {
                  position: absolute;
                  top: -36px;
                }
              }

              button {
                padding: 0;
                background-color: transparent;
                border: none;

                &:disabled {
                  cursor: not-allowed;
                  opacity: 0.5;
                }
              }

              &.capitalize {
                text-transform: capitalize;
              }

              &.actionMenu {
                @include onMobile {
                  width: 20px;
                  padding-left: 15px;
                }
              }

              &.title {
                padding-left: 15px;
                display: flex;
                align-items: center;

                @include onMobile {
                  min-height: 70px;
                  padding-right: 0;
                  padding-left: 0;
                  margin-left: 0;
                  justify-content: space-between;
                  font-size: 18px;
                  line-height: 25px;
                  font-weight: 600;
                }

                .newVideosAdded {
                  position: absolute;
                  font-size: 14px;
                  font-weight: bold;
                  color: $yellow;
                }

                .innerContainer {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding-top: 28px;
                  padding-bottom: 28px;

                  /* .infoContainer {
                    @include onMobile {
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                    }
                  } */

                  @include onMobile {
                    padding-top: 0;
                    padding-bottom: 0;
                  }

                  .titleContainer {
                    display: flex;

                    @include onMobile {
                      font-weight: 600;
                      font-family: 18px;
                      line-height: 25px;
                    }

                    .new {
                      display: flex;
                      justify-content: center;
                      min-width: 58px;
                      height: 29px;
                      font-size: 18px;
                      font-weight: 500;
                      line-height: 27px;
                      color: white;
                      background-color: var(--primary);
                      border-radius: 2px;
                    }
                  }

                  .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 50px;
                    width: 50px;
                    height: 50px;
                    margin-right: 16px;
                    border: 2px solid black;
                    border-radius: 50%;

                    @include onMobile {
                      width: 36px;
                      height: 36px;
                      min-width: 36px;
                      border-width: 1.5px;

                      img {
                        width: 20px !important;
                      }
                    }

                    img {
                      width: 31px;
                    }
                  }

                  .publishedDate {
                    display: none;

                    @include onMobile {
                      display: block;
                      margin-top: 1px;
                      font-size: 14px;
                      line-height: 18px;
                      color: #666666;
                      font-weight: normal;
                    }
                  }
                }

                img {
                  width: 48px;
                  height: 48px;

                  @include onMobile {
                    width: 36px;
                    height: 36px;
                  }
                }

                .playButton {
                  display: none;

                  @include onMobile {
                    display: flex;
                    align-items: center;
                  }
                }
              }

              &.owner {
                @include onMobile {
                  display: none;
                }
              }

              &.publishedDate {
                @include onMobile {
                  display: none;
                }
              }

              &.actions {
                @include onMobile {
                  display: none;
                }
              }

              &.viewedCount {
                @include onMobile {
                  height: unset;
                  padding: 0;
                  padding-left: 20px;
                  font-size: 20px;
                  line-height: 125%;
                  white-space: nowrap;
                }
              }

              &.buttons {
                //  display: flex;
                //  flex-direction: row-reverse;
                //  align-items: center;
                //  gap: 24px;

                @include onMobile {
                  display: none;
                }

                &.actions {
                  @include onMobile {
                    display: block;
                    height: unset;
                    padding: 0;
                    display: table-cell;
                  }
                }

                .buttonsContainer {
                  display: flex;
                  flex-direction: row-reverse;
                  align-items: center;
                  justify-content: flex-end;
                  gap: 24px;
                }

                .duration {
                  text-align: center;
                  min-width: 80px;
                }
              }
            }

            &:hover::before {
              content: '';
              width: 100%;
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              background-color: #f9f9f9;
              z-index: -1;

              @include onMobile {
                display: none;
              }
            }
          }
        }
      }
    }

    .empty {
      margin: 0;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translateX(50%);
      text-align: center;
      font-weight: 400;
      font-size: 42px;
      line-height: 114%;
      color: $text;

      @include onMobile {
        position: unset;
        top: unset;
        right: unset;
        transform: unset;
        margin: 32px 0;
        font-size: 32px;
      }
    }

    .paginationContainer {
      display: flex;
      justify-content: flex-end;

      @include onMobile {
        justify-content: center;
      }

      .buttonsList {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;

        @include onMobile {
          flex-wrap: wrap;
        }
      }

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;

        &.disabled {
          opacity: 0.4;

          a {
            cursor: not-allowed;
          }
        }

        &.active {
          a {
            width: 24px;
            text-align: center;
            font-family: 'Inter';
            line-height: 125%;
            border-radius: 4px;
            background-color: $pink;
            color: #ffffff;
          }
        }

        a {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 125%;
          text-decoration: none;
          color: $text;
        }
      }
    }
  }
}
