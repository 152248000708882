@import '../../assets/styles/mixins';

.DropdownMenu {
  position: relative;

  &.trashBin {
    .menu {
      width: 374px;

      @include onMobile {
        width: 250px;
      }
    }
  }

  @include onMobile {
    display: flex;
    justify-content: flex-end;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    background-color: transparent;
    border: none;
  }

  .menu {
    width: 299px;
    padding-top: 24px;
    padding-left: 32px;
    padding-right: 28px;
    padding-bottom: 25px;
    display: none;
    position: absolute;
    bottom: -132px;
    right: 100%;
    z-index: 111111;
    box-shadow: 0px 4px 24px 0px #0000001c;
    border-radius: 10px;
    background-color: white;

    &.isVisible {
      display: block;
    }

    @include onMobile {
      width: 202px;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 17px;
      padding-right: 11px;
      bottom: -55px;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 29px;
      list-style: none;

      @include onMobile {
        gap: 16px;
      }

      li {
        button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          font-size: 22px;
          line-height: 33px;
          color: var(--text);

          @include onMobile {
            font-size: 18px;
            line-height: 27px;
          }

          img {
            position: relative;
          }

          &:disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
