.PreviewSectionsMobile {
  width: 100vw;
  display: flex;
  gap: 6px;
  margin: 0;
  padding: 0;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 7px;
  list-style: none;
  overflow-x: auto;
}
