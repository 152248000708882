@import '../../assets/styles//mixins';

.EmojiPickerWithButton {
  position: absolute;
  // top: 75px;
  // right: 40px;

  .emojiButton {
    width: 48px;
    height: 48px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    @include onMobile {
      position: relative;
      width: 24px;
      height: 24px;

      img {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      right: 1px;
      bottom: 3px;
      width: 16px;
      height: 16px;
      background-image: url(../../assets/images/view-meeting/plus.svg);
      background-size: contain;
      background-repeat: no-repeat;

      @include onMobile {
        width: 12px;
        height: 12px;
        bottom: -6px;
        right: -6px;
      }
    }
  }
}
