@import '../../assets/styles/mixins';

.Meeting {
  max-width: calc(100vw / var(--zoom) - var(--sidebar-width));
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 42px;
  padding-right: 46px;
  overflow-x: hidden;
  transition: all 0.3s;

  @include onMobile {
    max-width: unset;
    padding: 0;
  }

  .editButtonContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 30px;
    margin-bottom: 27px;
  }

  .breadcrumbsContainer {
    width: 100%;
    margin-bottom: 27px;

    @include onMobile {
      display: none;
    }
  }

  .headerContainer {
    width: 100%;

    header {
      width: 100%;
    }
  }
}
