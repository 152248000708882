@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.CreateMeetingFooter {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 310px;
  padding-left: 20px;
  padding-right: 40px;

  @include onMobile {
    display: none;
  }

  .footerContainer {
    flex: 1;
    width: 100%;

    .sectionsListContainer {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;

      .switch {
        position: absolute;
        top: 98px;
        left: -10px;
        z-index: 1;
        cursor: pointer;

        &.switchRight {
          top: 91px;
          left: unset;
          right: -30px;
          transform: rotate(180deg);
        }
      }

      .sectionsList {
        position: relative;
        display: flex;
        //  justify-content: center;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        padding-top: 34px;
        list-style: none;
        overflow: hidden;

        .inner {
          position: absolute;
          height: 212px;
          display: flex;
          align-items: center;
          //  gap: 40px;
          padding-left: 30px;
          transition: all 0.2s;

          li {
            padding: 0;
            margin: 0;

            &.section {
              position: relative;
              //  width: 232px;
              //  min-width: 232px;
              //  height: 140px;
              width: 235px;
              min-width: 235px;
              height: 148px;
              cursor: pointer;

              .playIcon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 55px;
                height: 55px;
              }

              &.active {
                &::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: block;
                  width: 235px;
                  height: 148px;
                  border: 5px solid $red;
                  border-radius: 16px;
                  pointer-events: none;
                }
              }

              .username {
                position: absolute;
                z-index: 1;
                color: white;
                background-color: $primary;
                padding-left: 15px;
                padding-right: 15px;
                border-radius: 15px;
                white-space: nowrap;

                &.defaultIcon {
                  filter: invert(100%);
                }
              }

              .sectionContentContainer {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $primary;
                border-radius: 16px;
              }

              .title {
                position: absolute;
                left: 0;
                bottom: -50px;
                display: flex;
                align-items: center;
                width: 100%;
                height: 24px;
                padding-left: 18px;
                padding-right: 18px;
                border-radius: 16px;

                h1 {
                  width: 100%;
                  margin: 0;
                  color: $text;
                  font-size: 16px;
                  text-align: center;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                input {
                  width: 100%;
                  font-family: Poppins;
                  font-size: 22px;
                  font-weight: 500;
                  line-height: 33px;
                  text-align: center;
                  color: black;
                  background-color: transparent;
                  border: none;

                  &:focus {
                    outline: none;
                  }
                }
              }

              .audioBackgroundContainer {
                width: 100%;
                height: 100%;
                background-color: $primary;
                border-radius: 16px;

                span {
                  width: 100%;
                  line-height: 140px;
                  display: block;
                  text-align: center;
                  font-weight: bold;
                  color: #ffffff;
                }
              }

              &.contributed {
                .usericon {
                  position: absolute;
                  bottom: -10px;
                  right: -10px;
                  width: 60px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 60px;
                  background-color: white;
                  border-radius: 50%;
                  filter: invert(100%);
                  z-index: 2;

                  &.withAvatar {
                    filter: none;
                  }

                  img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    object-fit: cover;
                  }
                }
              }

              .noteSectionContent {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                background-color: #efede9;
                border-radius: 16px;

                img {
                  width: 100px;
                  height: auto;
                }
              }

              .questionSectionContent,
              .assignedSectionContent {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 56px;
                font-weight: bold;
                height: 100%;
                width: 100%;
                border-radius: 16px;
                background-color: #efefef;
              }

              .assignedSectionContent {
                span {
                  position: absolute;
                  font-size: 16px;
                  font-weight: bold;
                }
              }

              .processingSectionContent {
                height: 100%;
                width: 100%;
                padding: 0 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $primary;
                border-radius: 16px;

                .progressBarOuter {
                  position: relative;
                  width: 100%;
                  height: 4px;
                  border-radius: 16px;
                  overflow: hidden;
                  background: white;

                  .progressBarInner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    background-color: $red;
                  }
                }
              }

              &.placeholder {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 16px;
                background-color: #efefef;

                span {
                  font-weight: bold;
                  position: absolute;
                  white-space: nowrap;
                }
              }

              .saveIcon {
                position: absolute;
                z-index: 1;
                top: 12px;
                left: 16px;
              }

              video,
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 16px;
              }
            }

            .sectionMenuContainer {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 235px;
              min-width: 235px;
              max-width: 235px;
              height: 140px;
            }
          }
        }
      }
    }
  }
}
