@import '../../assets/styles/variables';

.dialog {
  max-width: 937px;
}

.backdrop {
  min-width: calc(100vw / var(--zoom));
  min-height: calc(100vh / var(--zoom));
}

.MeetingDetailsModal {
  padding-top: 36px;
  padding-left: 55px;
  padding-right: 33px;
  padding-bottom: 27px;
  position: relative;
  border-radius: 15px;
  background-color: #f9f9f9;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 54px;

    .title {
      margin: 0;
      font-family: Poppins;
      font-size: 48px;
      font-weight: 600;
      line-height: 72px;
      color: var(--black);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      justify-content: space-between;
      margin-bottom: 0;
      padding-bottom: 26px;
      padding-top: 26px;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: var(--text);
      border-bottom: 1px solid #d9d9d9;

      .owner {
        display: flex;
        flex-direction: column;

        .ownerTitle {
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
        }
      }

      .label {
        color: var(--black);
      }
    }
  }
}
