@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.Buttons {
  position: relative;

  &.chrome {
    ul {
      li {
        .recordControl {
          position: relative;
          top: -4px;
        }
      }
    }
  }

  ul {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    padding: 0;
    list-style: none;

    @include onMobile {
      gap: 20px;
    }

    li {
      padding: 0;
      cursor: pointer;

      &:first-child {
        button {
          @include onMobile {
            width: 12px;
          }
        }
      }

      &.fullscreen {
        margin-left: auto;

        img {
          width: 24px;
          height: 24px;

          @include onMobile {
            width: 14px;
            height: 14px;
          }
        }
      }

      button {
        width: 48px;
        height: 48px;
        padding: 0;
        border: none;
        background-color: transparent;

        @include onMobile {
          width: unset;
          height: unset;

          img {
            width: auto;
            height: 14px;
          }
        }
      }

      .recordControl {
        display: flex;
        align-self: center;
        align-items: center;
        gap: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 22px;
        line-height: 109%;
        color: #ffffff;
        border: 2px solid white;
        border-radius: 5px;

        .stopButton {
          width: 18px;
          height: 18px;
        }
      }

      .recordButton {
        width: 20px;
        height: 20px;
        background-color: $red;
        border-radius: 50%;
      }

      .stopButton {
        width: 22px;
        height: 22px;
        background-color: white;
      }
    }
  }
}
