.File {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  gap: 10px;
  width: calc(174px / var(--zoom));
  padding-top: 25px;

  .deleteIcon {
    position: absolute;
    top: 12px;
    right: 18px;
    cursor: pointer;
    width: calc(24px / var(--zoom));
    height: calc(24px / var(--zoom));
    border-radius: 50%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: var(--primary);
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }
  }

  .image {
    width: calc(119px / var(--zoom));
    height: calc(119px / var(--zoom));
    object-fit: cover;
  }

  .fileName {
    font-family: Poppins;
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0%;
    color: var(--text);
  }
}
