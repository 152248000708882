@import '../../assets/styles/mixins';

.backdrop {
  min-width: calc(100vw / var(--zoom));
  min-height: calc(100vh / var(--zoom));
}

div:has(.dialog) {
  z-index: 99999;
}

.dialog {
  max-width: 1100px;

  @include onMobile {
    z-index: 99999 !important;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.ViewerNotesModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-radius: 25px;
  height: 700px;
  padding: 60px 60px 40px;

  @include onMobile {
    justify-content: unset;
    height: 100dvh;
    padding-top: 81px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0;
  }

  .closeButton {
    position: absolute;
    top: 22px;
    right: 20px;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .notesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .notes {
      width: 100%;
      height: 474px;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      overflow-y: auto;
      background-color: #f5f6f7;
      box-shadow: 0px 16px 34px -18px rgba(0, 76, 189, 0.08);
      border-radius: 20px;

      @include onMobile {
        height: 255px;
        margin-bottom: 16px;
        padding-top: 16px;
        padding-right: 20px;
        padding-left: 20px;
        padding-bottom: 16px;
        font-size: 18px;
        border-radius: 18px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }
}
