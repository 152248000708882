@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.dialog {
  max-width: 937px;
}

.backdrop {
  min-width: calc(100vw / var(--zoom));
  min-height: calc(100vh / var(--zoom));
}

.MeetingNotesModal {
  position: relative;
  border-radius: 10px;
  height: 748px;

  .header {
    padding-top: 31px;
    padding-left: 49px;
    padding-right: 49px;
    margin-bottom: 27px;

    .closeIcon {
      position: absolute;
      top: 45px;
      right: 49px;
      width: 28px;
      height: 28px;
      display: block;
      font-size: 0;
      background-image: url(../../assets/images/closeIcon.svg);
      background-size: cover;
      cursor: pointer;
    }

    h1 {
      margin: 0;
      font-size: 48px;
      font-weight: 600;
      line-height: 72px;
      color: var(--black);
    }
  }
  .editorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 49px;
    padding-right: 49px;

    .label {
      width: 100%;
      margin-bottom: 13px;
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      color: var(--black);
    }

    .editorWrapper {
      width: 100%;

      .toolbarIcon {
        height: unset;
        margin: 0;
        padding: 0;
        border: none;

        &.left {
          transform: scale(0.7);
        }
      }

      .fontSizeDropdown {
        width: 100px;
        overflow-y: auto;
      }

      .editor {
        height: 422px;
        padding-top: 26px;
        padding-left: 38px;
        padding-right: 38px;
        padding-bottom: 26px;
        border: 1px solid #666666;
        border-radius: 18px;

        li {
          span {
            line-height: 100%;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    .saveButton {
      position: absolute;
      right: 74px;
      bottom: 43px;
      width: 70px;
      height: 70px;
      background-color: var(--primary);
      border-radius: 50%;
      border: none;

      img {
        width: 29px;
      }
    }
  }
}
