.CreateMeetingButtons {
  display: flex;
  align-items: center;
  gap: 23px;

  .roundButton {
    width: 52px;
    height: 52px;
    padding: 0;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid #666666;

    &.undo {
      img {
        width: 50%;
        height: 50%;
      }
    }
  }
}
