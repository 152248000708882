@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.ReplyBox {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  margin-bottom: 53px;

  @include onMobile {
    align-items: center;
    gap: 13px;
    margin-bottom: 27px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .emojiButton {
    position: absolute;
    z-index: 1;
    right: 180px;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    width: 48px;
    height: 48px;
    background-color: white;
    border-radius: 50%;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    cursor: pointer;

    @include onMobile {
      width: 24px;
      height: 24px;
      right: 20px;
      top: calc(50% - 7px);
      z-index: 2222;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      right: 1px;
      bottom: 3px;
      width: 16px;
      height: 16px;
      background-image: url(../../assets/images/view-meeting/plus.svg);
      background-size: contain;
      background-repeat: no-repeat;

      @include onMobile {
        display: none;
      }
    }
  }

  .userPhoto {
    display: none;

    @include onMobile {
      display: block;
      width: 39px;
      height: 39px;
      min-width: 39px;
      min-height: 39px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .submitButton {
    display: block;
    width: 180px;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: white;
    background-color: var(--primary);
    border-radius: 7px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: none;

    @include onMobile {
      display: none;
    }
  }

  .textarea {
    box-sizing: border-box;
    width: 100%;
    min-height: 66px;
    padding-top: 16px;
    padding-bottom: 15px;
    padding-left: 22px;
    padding-right: 62px;
    overflow: hidden;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    background-color: white;
    border-radius: 8px;
    border: none;
    resize: none;
    border: 1px solid #666666;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    @include onMobile {
      min-height: 33px;
      padding-top: 3px;
      padding-left: 0;
      padding-right: 30px;
      padding-bottom: 8px;
      font-size: 16px;
      line-height: 24px;
      border: none;
      border-bottom: 1px solid #ddd;
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }

    &:focus {
      outline: none;
    }
  }
}
