.FavoritedPopup {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 380px;
  padding: 20px;
  transform: translate(-50%, calc(-100% - 10px));
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;

  &.visible {
    opacity: 1;
  }
}
