.TabContentTitle {
  // padding-top: 25px;
  // padding-left: 40px;
  //  padding-right: 40px;

  margin-bottom: 51px;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  text-align: left;
  color: var(--black);

  &.export {
    font-size: 36px;
    line-height: 54px;
  }
}
