.SectionMenu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  /*  &.emptyList {
    margin-left: 120px;
  } */

  .toggle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    cursor: pointer;

    span {
      font-family: Poppins;
      font-size: 26px;
      font-weight: 600;
      line-height: 39px;
    }
  }

  .fileInput {
    display: none;
  }
}
