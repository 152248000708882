@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.Section {
  position: relative;
  width: 235px;
  min-width: 235px;
  height: 148px;
  border-radius: 20px;
  cursor: pointer;

  &.current {
    border: 3px solid $red;

    .title {
      bottom: -53px;
    }
  }

  .title {
    position: absolute;
    left: 0;
    bottom: -50px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 24px;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 16px;

    span {
      width: 100%;
      font-family: Poppins;
      font-size: 22px;
      font-weight: 500;
      line-height: 33px;
      text-align: center;
      color: black;
      background-color: transparent;
      border: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .sectionContent {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 17px;
  }

  .noteSectionContent {
    background-color: #efede9;
  }

  .audioImageBackground,
  video,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 17px;
  }

  .audioImageBackground {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
