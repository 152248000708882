@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.dialog {
  max-width: 746px;
  max-height: 652px;

  @include onMobile {
    max-width: 100%;
    max-height: 100%;
  }
}

.backdrop {
  min-width: calc(100vw / var(--zoom));
  min-height: calc(100vh / var(--zoom));
}

.FileUploadProgressModal {
  padding: 48px;
  position: relative;
  border-radius: 15px;

  @include onMobile {
    padding: 32px 24px;
  }

  .closeIcon {
    display: none;
    position: absolute;
    top: 36px;
    right: 36px;
    cursor: pointer;

    @include onMobile {
      display: block;
      top: 14px;
      right: 18px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin-bottom: 24px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 133%;
      color: $text;

      @include onMobile {
        font-size: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      font-size: 24px;
      line-height: 100%;
      color: $text;

      @include onMobile {
        margin-bottom: 24px;
        font-size: 20px;
      }
    }

    .progressContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      width: 232px;

      .progressBackground {
        position: relative;
        width: 100%;
        height: 16px;
        background: #f0f0f0;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
      }

      .progressBar {
        position: absolute;
        left: 0;
        z-index: 1;
        height: 16px;
        background-color: #f8585c;
        transition: width 0.1s ease;
      }

      .percentage {
        font-size: 18px;
      }
    }
  }
}
